export default {
  //Para ordenes de cobro
  ADD_RESERVATION_STEP1: 'ADD_RESERVATION_STEP1',
  ADD_ALL_RESERVATIONS_STEP1: 'ADD_ALL_RESERVATIONS_STEP1',
  DELETE_RESERVATION_STEP1: 'DELETE_RESERVATION_STEP1',
  DELETE_ALL_RESERVATIONS_STEP1: 'DELETE_ALL_RESERVATIONS_STEP1',
  DELETE_ALL_RESERVATIONS: 'DELETE_ALL_RESERVATIONS',
  //Guardar la reservacion confirmadad
  SAVE_RESERVATIONS_STEP2: 'SAVE_RESERVATIONS_STEP2',
  SAVE_CURRENCY: 'SAVE_CURRENCY',
  //Setear el id de la orden si pasa al paso 3
  SAVE_ORDER_CHARGE_ID: 'SAVE_ORDER_CHARGE_ID',
  SAVE_CLIENT: 'SAVE_CLIENT',

  //Para movimientos paso 3F
  ADD_TRANSACTION_STEP3: 'ADD_TRANSACTION_STEP3',
  ADD_ALL_TRANSACTIONS_STEP3: 'ADD_ALL_TRANSACTIONS_STEP3',
  DELETE_TRANSACTION_STEP3: 'DELETE_TRANSACTION_STEP3',
  DELETE_ALL_TRANSACTIONS_STEP3: 'DELETE_ALL_TRANSACTIONS_STEP3',

  ADD_RESERVATION_STEP1_PAYMENT: 'ADD_RESERVATION_STEP1_PAYMENT',
  ADD_ALL_RESERVATIONS_STEP1_PAYMENT: 'ADD_ALL_RESERVATIONS_STEP1_PAYMENT',
  DELETE_RESERVATION_STEP1_PAYMENT: 'DELETE_RESERVATION_STEP1_PAYMENT',
  DELETE_ALL_RESERVATIONS_PAYMENT: 'DELETE_ALL_RESERVATIONS_PAYMENT',
  DELETE_ALL_RESERVATIONS_STEP1_PAYMENT:
    'DELETE_ALL_RESERVATIONS_STEP1_PAYMENT',
  //Guardar la reservacion confirmadad
  SAVE_RESERVATIONS_STEP2_PAYMENT: 'SAVE_RESERVATIONS_STEP2_PAYMENT',
  //Setear el id de la orden si pasa al paso 3
  SAVE_ORDER_CHARGE_ID_PAYMENT: 'SAVE_ORDER_CHARGE_ID_PAYMENT',
  SAVE_PROVIDER_PAYMENT: 'SAVE_PROVIDER_PAYMENT',
  SAVE_EXCHANGE_RATE: 'SAVE_EXCHANGE_RATE',
  SAVE_CURRENCY_PAYMENT: 'SAVE_CURRENCY_PAYMENT',

  //Para movimientos paso 3F
  ADD_TRANSACTION_STEP3_PAYMENT: 'ADD_TRANSACTION_STEP3_PAYMENT',
  ADD_ALL_TRANSACTIONS_STEP3_PAYMENT: 'ADD_ALL_TRANSACTIONS_STEP3_PAYMENT',
  DELETE_TRANSACTION_STEP3_PAYMENT: 'DELETE_TRANSACTION_STEP3_PAYMENT',
  DELETE_ALL_TRANSACTIONS_STEP3_PAYMENT:
    'DELETE_ALL_TRANSACTIONS_STEP3_PAYMENT',

  //Para configuraciones de sistema
  SAVE_EXCHANGE_RATE_SYSTEM: 'SAVE_EXCHANGE_RATE_SYSTEM',
};
