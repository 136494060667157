import types from '../actions/types';
const initialState = {
  reservations: [],
  reservationsSelected: [],
  orderPaymentId: null,
  provider: null,
  exchangeRate: 0.0,
  //Transacciones selecionadas en el paso 3
  transactions: [],
  //Son las reservaciones seleccionadas en el paso 1, estas se podran ir elimando
  reservastionsStep3_1: [],
  //Es el listado de la reservaciones relacionadas con su ingreso
  reservationsTransactionList: [],
  reservationsTransactionSelected: [],
  currencySelected: 'MXN',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_RESERVATION_STEP1_PAYMENT:
      return {
        ...state,
        reservations: [...state.reservations, action.new_item],
      };
    case types.ADD_ALL_RESERVATIONS_STEP1_PAYMENT:
      let _reservations = [...state.reservations];
      action.array_item.forEach((element) => {
        _reservations.push(element);
      });
      return { ...state, reservations: _reservations };
    case types.DELETE_RESERVATION_STEP1_PAYMENT:
      return {
        ...state,
        reservations: [
          ...state.reservations.filter((element) => element.id !== action.id),
        ],
      };
    case types.DELETE_ALL_RESERVATIONS_STEP1_PAYMENT:
      return {
        ...state,
        reservations: [],
        reservationsSelected: [],
        orderPaymentId: null,
        provider: null,
        transactions: [],
        reservastionsStep3_1: [],
        reservationsTransactionList: [],
        reservationsTransactionSelected: [],
        //currencySelected: 'MXN',
        exchangeRate: 0.0,
      };

    case types.DELETE_ALL_RESERVATIONS_PAYMENT:
      return {
        ...state,
        reservations: [],
        reservationsSelected: [],
        transactions: [],
        reservastionsStep3_1: [],
        reservationsTransactionList: [],
        reservationsTransactionSelected: [],
        exchangeRate: 0.0,
      };

    case types.SAVE_RESERVATIONS_STEP2_PAYMENT:
      return { ...state, reservationsSelected: action.reservations };
    case types.SAVE_ORDER_CHARGE_ID_PAYMENT:
      return { ...state, orderPaymentId: action.id };
    case types.SAVE_PROVIDER_PAYMENT:
      return { ...state, provider: action.provider };

    case types.ADD_TRANSACTION_STEP3_PAYMENT:
      return {
        ...state,
        transactions: [...state.transactions, action.new_item],
      };
    case types.ADD_ALL_TRANSACTIONS_STEP3_PAYMENT:
      let _transactions = [...state.transactions];
      action.array_item.forEach((element) => {
        _transactions.push(element);
      });
      return { ...state, transactions: _transactions };
    case types.DELETE_TRANSACTION_STEP3_PAYMENT:
      return {
        ...state,
        transactions: [
          ...state.transactions.filter((element) => element.id !== action.id),
        ],
      };
    case types.DELETE_ALL_TRANSACTIONS_STEP3_PAYMENT:
      return { ...state, transactions: [] };

    case types.SAVE_CURRENCY_PAYMENT:
      return { ...state, currencySelected: action.currency };

    case types.SAVE_EXCHANGE_RATE:
      return { ...state, exchangeRate: action.exchangeRate };

    default:
      return state;
  }
}
