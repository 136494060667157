import types from '../actions/types';
const initialState = {
  reservations: [],
  reservationsSelected: [],
  orderChargeId: null,
  client: null,
  //Transacciones selecionadas en el paso 3
  transactions: [],
  //Son las reservaciones seleccionadas en el paso 1, estas se podran ir elimando
  reservastionsStep3_1: [],
  //Es el listado de la reservaciones relacionadas con su ingreso
  reservationsTransactionList: [],
  reservationsTransactionSelected: [],
  currencySelected: 'MXN',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_RESERVATION_STEP1:
      return {
        ...state,
        reservations: [...state.reservations, action.new_item],
      };
    case types.ADD_ALL_RESERVATIONS_STEP1:
      let _reservations = [...state.reservations];
      action.array_item.forEach((element) => {
        _reservations.push(element);
      });
      return { ...state, reservations: _reservations };
    case types.DELETE_RESERVATION_STEP1:
      return {
        ...state,
        reservations: [
          ...state.reservations.filter((element) => element.id !== action.id),
        ],
      };
    case types.DELETE_ALL_RESERVATIONS_STEP1:
      return {
        ...state,
        reservations: [],
        reservationsSelected: [],
        orderChargeId: null,
        client: null,
        transactions: [],
        reservastionsStep3_1: [],
        reservationsTransactionList: [],
        reservationsTransactionSelected: [],
        //currencySelected: 'MXN',
      };

    case types.DELETE_ALL_RESERVATIONS:
      return {
        ...state,
        reservations: [],
        reservationsSelected: [],
        transactions: [],
        reservastionsStep3_1: [],
        reservationsTransactionList: [],
        reservationsTransactionSelected: [],
      };

    case types.SAVE_RESERVATIONS_STEP2:
      return { ...state, reservationsSelected: action.reservations };
    case types.SAVE_ORDER_CHARGE_ID:
      return { ...state, orderChargeId: action.id };
    case types.SAVE_CLIENT:
      return { ...state, client: action.client };

    case types.ADD_TRANSACTION_STEP3:
      return {
        ...state,
        transactions: [...state.transactions, action.new_item],
      };
    case types.ADD_ALL_TRANSACTIONS_STEP3:
      let _transactions = [...state.transactions];
      action.array_item.forEach((element) => {
        _transactions.push(element);
      });
      return { ...state, transactions: _transactions };
    case types.DELETE_TRANSACTION_STEP3:
      return {
        ...state,
        transactions: [
          ...state.transactions.filter((element) => element.id !== action.id),
        ],
      };
    case types.DELETE_ALL_TRANSACTIONS_STEP3:
      return { ...state, transactions: [] };

    case types.SAVE_CURRENCY:
      return { ...state, currencySelected: action.currency };

    default:
      return state;
  }
}
