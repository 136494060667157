import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import ThemeProvider from '../containers/ThemeProvider';
import initStore from '../redux/store';
import 'antd/dist/antd.css';
import '@glidejs/glide/dist/css/glide.core.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import '../style/global.css';
import { initGA, logPageView } from '../utils/analytics';
import Network from '../utils/network';

class CustomApp extends App {
  constructor() {
    super(...arguments);
    this.state = {
      hasError: false,
      errorEventId: undefined,
    };
  }

  componentDidMount() {
    console.log();
    if (process.env.NEXT_PUBLIC_GOOGLE_ANALITYCS === "true") {
      if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
      }
      logPageView();
    }

    setInterval(function () {
      Network.check();
    }, 60000);
  }

  componentDidUpdate() {
    if (process.env.NEXT_PUBLIC_GOOGLE_ANALITYCS === "true") {
      if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
      }
      logPageView();
    }
  }


  render() {
    const { Component, pageProps, store, err } = this.props;
    return (
      <Provider store={store}>
        <ThemeProvider>
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default withRedux(initStore)(CustomApp);
