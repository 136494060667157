import types from '../actions/types';
const initialState = {
    exchangeRateSystem: null,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SAVE_EXCHANGE_RATE_SYSTEM:
            return { ...state, exchangeRateSystem: action.exchangeRateSystem };
        default:
            return state
    }
};